<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'remove-account'">
        <span class="alert-text">Сигурни ли сте, че искате да изтриете този акаунт от системата ?</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="deleteUser(modal.obj.uid)">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>

    <div class="modal-md modal" *ngIf="modal.cntx == 'add-user'">
        <div class="modal-ttl">Добавяне на потребител</div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;">
            <span class="label">Наименование</span>
            <input type="text" placeholder="Въведете име на акаунт" [(ngModel)]="modal.obj.username" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;position:relative;">
            <span class="label">Имейл</span>
            <input type="text" placeholder='Въведете Имейл' [(ngModel)]="modal.obj.email" />
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;position:relative;">
            <span class="label">Парола</span>
            <input type="text" placeholder="Въведете парола" [(ngModel)]="modal.obj.password" />
            <div class="after-action" (click)="generateRandomPassword(12)" style="top: 36px; right: 25px;"><i class="icon random"></i></div>
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;">
            <span class="label">Тип акаунт</span>
            <select [(ngModel)]="modal.obj.role" (change)="switchAccountType()">
                <option value="0" disabled>Изберете тип акаунт</option>
                <option value="1">Склад</option>
                <option value="2">Дилър</option>
                <option value="3">Официален дилър</option>
                <option value="4">Сервиз - Дронове</option>
                <option value="5">Продавач - Дронове</option>
            </select>
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;" *ngIf="modal.obj.role == '1'">
            <span class="label">Склад</span>
            <select id="storehouse_id" [(ngModel)]="modal.obj.storehouse_id">
                <option value="-1" disabled>Моля изберете склад</option>
                <option *ngFor="let storehouse of availableStorehouses" value="{{ storehouse.uid }}">{{ storehouse.name }}</option>
            </select>
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;" *ngIf="modal.obj.role == '2'">
            <span class="label">Дилър</span>
            <select id="dealer_id" [(ngModel)]="modal.obj.dealer_id">
                <option value="-1" disabled>Моля изберете дилър</option>
                <option *ngFor="let dealer of availableDealers" value="{{ dealer.uid }}">{{ dealer.name }}</option>
            </select>
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;" *ngIf="modal.obj.role == '4' || modal.obj.role == '5'">
            <span class="label">Име на сервиз</span>
            <input type="text" placeholder="Въведете парола" [(ngModel)]="modal.obj.drone_service" />
        </div>
        <label class="container modal-row">Администраторски права
            <input type="checkbox" checked="checked" [(ngModel)]="modal.obj.admin">
            <span class="checkmark"></span>
          </label>
        <div class="btn-line" [ngClass]="{ 'margin-eighty':modal.obj.role == '3'}">
            <div class="btn negative" (click)="closeModal()">Отказ</div>
            <div class="btn positive" [ngClass]="{ 'disabled':modal.obj.username.length == 0 || modal.obj.email.length == 0 || modal.obj.password.length == 0 || modal.obj.role == '1' && modal.obj.storehouse_id == '-1' || modal.obj.role == '2' && modal.obj.dealer_id == '-1' }" (click)="AddUser()">Добавяне</div>
        </div>
    </div>

    <div class="modal-md modal" *ngIf="modal.cntx == 'edit-user'">
        <div class="modal-ttl">Промяна на потребител</div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;">
            <span class="label">Наименование</span>
            <input type="text" placeholder="Въведете име на акаунт" [(ngModel)]="modal.obj.username" />
        </div>
        <div class="col-xl-6 col-lg-6 input-row" style="float:left;position:relative;">
            <span class="label">Имейл</span>
            <input type="text" placeholder='Въведете Имейл' [(ngModel)]="modal.obj.email" />
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;position:relative;">
            <span class="label">Парола</span>
            <input type="text" placeholder="Въведете парола" [(ngModel)]="modal.obj.password" />
            <div class="after-action" (click)="generateRandomPassword(12)" style="top: 36px; right: 25px;"><i class="icon random"></i></div>
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;">
            <span class="label">Тип акаунт</span>
            <select [(ngModel)]="modal.obj.role" (change)="switchAccountType()">
                <option value="0" disabled>Изберете тип акаунт</option>
                <option value="1">Склад</option>
                <option value="2">Дилър</option>
                <option value="3">Официален дилър</option>
                <option value="4">Сервиз - Дронове</option>
                <option value="5">Продавач - Дронове</option>
            </select>
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;" *ngIf="modal.obj.role == '1'">
            <span class="label">Склад</span>
            <select id="storehouse_id"  [(ngModel)]="modal.obj.storehouse_id">
                <option value="-1" disabled>Моля изберете склад</option>
                <option *ngFor="let storehouse of availableStorehouses" value="{{ storehouse.uid }}">{{ storehouse.name }}</option>
            </select>
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;" *ngIf="modal.obj.role == '2'">
            <span class="label">Дилър</span>
            <select id="dealer_id" [(ngModel)]="modal.obj.dealer_id">
                <option value="-1" disabled>Моля изберете дилър</option>
                <option *ngFor="let dealer of availableDealers" value="{{ dealer.uid }}">{{ dealer.name }}</option>
            </select>
        </div>
        <div class="col-xl-12 col-lg-12 input-row" style="float:left;" *ngIf="modal.obj.role == '4' || modal.obj.role == '5'">
            <span class="label">Име на сервиз</span>
            <input type="text" placeholder="Въведете парола" [(ngModel)]="modal.obj.drone_service" />
        </div>
        <label class="container modal-row">Администраторски права
            <input type="checkbox" checked="checked" [(ngModel)]="modal.obj.admin">
            <span class="checkmark"></span>
          </label>
        <div class="btn-line" [ngClass]="{ 'margin-eighty':modal.obj.role == '3'}">
            <div class="btn negative" (click)="closeModal()">Отказ</div>
            <div class="btn positive" [ngClass]="{ 'disabled':modal.obj.username.length == 0 || modal.obj.email.length == 0 || modal.obj.password.length == 0 || modal.obj.role == '1' && modal.obj.storehouse_id == '-1' || modal.obj.role == '2' && modal.obj.dealer_id == '-1' }" (click)="EditUser()">Запис</div>
        </div>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'adding-user'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Добавяне на потребител...</span>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'editing-user'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Промяна на потребител...</span>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'removing-user'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Премахване на потребител...</span>
    </div>
</div>

<div class="content">
    <span class="page-title"> 
        <span class="name">Потребители</span>

        <div class="label-searched" (click)="clearSearched()" *ngIf="searched">
            <strong class="type" *ngIf="search_type == 'username'">Потребителско име:</strong>
            <strong class="type" *ngIf="search_type == 'email'">Имейл:</strong>
            <span *ngIf="search_type == 'username' || search_type == 'email'">{{ search }}</span>
            <span *ngIf="search_type == 'username' && search == '2'">Дилър</span>
            <i class="icon delete-label"></i>
        </div>
        <div class="search-title-box" *ngIf="!searched">
            <select [(ngModel)]="search_type" (change)="changedSearchType()">
                <option value="email">Имейл</option>
                <option value="username">Потребителско име</option>
            </select>
            <select [(ngModel)]="search" *ngIf="!searched && search_type == 'role'" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
                <option disabled selected value=''>Изберете тип</option>
                <option value="1">Склад</option>
                <option value="2">Дилър</option>
                <option value="3">Официален дилър</option>
                <option value="4">Сервиз - Дронове</option>
                <option value="5">Продавач - Дронове</option>
            </select>
            <input type="text" (keydown.enter)="searchAccounts()" [(ngModel)]="search"  *ngIf="!searched && search_type == 'username' || !searched && search_type == 'email'" />
            <div class="round-btn" (click)="searchAccounts()"  *ngIf="!searched"><i class="icon search-white"></i></div>
        </div> 

        <span (click)="openModal('add-user', { username:'', email:'', password:'', dealer_id:'-1', storehouse_id:'-1', role:'1', admin:false, 'drone_service':''  })" class="right-btn">Нов потребител</span>
    </span>
    <div class="table-wrapper">
        <div class="table-wrapper-header-row">
            <div class="col-xl-3 col-lg-3 text-center">Наименование</div>
            <div class="col-xl-3 col-lg-3 text-center">Тип на потребител</div>
            <div class="col-xl-3 col-lg-3 text-center">E-mail</div>
            <div class="col-xl-3 col-lg-3 text-center">Действия</div>
        </div>
        <div class="table-wrapper-row" *ngIf="!searched && accounts.length == 0">
            <div class="col-xl-12 col-lg-12 text-center">Няма добавени потребители</div>
        </div>
        <div class="table-wrapper-row" *ngIf="searched && accounts.length == 0">
            <div class="col-xl-12 col-lg-12 text-center">Няма октрити резултати</div>
        </div>
        <div class="table-wrapper-row" *ngFor="let account of accounts">
            <div class="col-xl-3 col-lg-3 text-center selectable">{{ account.username }}</div>
            <div class="col-xl-3 col-lg-3 text-center selectable">
                <span *ngIf="account.role == '5'">Продавач - Дронове <span *ngIf="account.admin == '1'">(Администратор)</span></span>
                <span *ngIf="account.role == '4'">Сервиз - Дронове <span *ngIf="account.admin == '1'">(Администратор)</span></span>
                <span *ngIf="account.role == '3'">Официален дилър <span *ngIf="account.admin == '1'">(Администратор)</span></span>
                <span *ngIf="account.role == '2'">Дилър <span *ngIf="account.admin == '1'">(Администратор)</span></span>
                <span *ngIf="account.role == '1'">Склад <span *ngIf="account.admin == '1'">(Администратор)</span></span>
            </div>
            <div class="col-xl-3 col-lg-3 text-center selectable">{{ account.email }}</div>
            <div class="col-xl-3 col-lg-3 text-center selectable">
                <div class="action-btn" (click)="openModal('edit-user', account)"><i class="icon edit"></i> <span class="tooltip">Промяна</span></div>
                <div class="action-btn negative" (click)="promptDelete(account.uid)"><i class="icon remove-white"></i> <span class="tooltip">Изтриване</span></div>
            </div>
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>

            <!-- <span (click)="switchPage(i + 1)" *ngFor="let page of total_pages_array;let i= index" [ngClass]="{ 'active':i + 1 == currentPage }">{{ i + 1 }}</span> -->
        </div>
    </div>
</div>