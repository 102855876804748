import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LocalSystemService {
  loggedCheckInterval;
  loggedIn:any = false;
  user:any = { role:-1, admin:"0", name:"" };

  deliveries:any = [];
  dealers:any = [];

  main:any;
  public_document_name:any = "dostavka";

  constructor(private http:HttpClient, public router:Router) {
    this.checkLogged();
    let dt = new Date();
    this.public_document_name = "dostavka-" + this.returnPadZero(dt.getDate(), 2) + "-" + this.returnPadZero(dt.getMonth() + 1, 2) + "-" + dt.getFullYear() + "-" + this.returnPadZero(dt.getHours(), 2) + "-" + this.returnPadZero(dt.getMinutes(), 2); 
    setInterval(() => {
      this.public_document_name = "dostavka-" + this.returnPadZero(dt.getDate(), 2) + "-" + this.returnPadZero(dt.getMonth() + 1, 2) + "-" + dt.getFullYear() + "-" + this.returnPadZero(dt.getHours(), 2) + "-" + this.returnPadZero(dt.getMinutes(), 2); 
    }, 60000);
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe((event: NavigationEnd) => {
      if(event.url.indexOf('/edit-sale') > -1) { } else {
        localStorage.setItem('sales_search_obj', null);
      }
    });
  }

  checkLogged() {
    this.http.get("api/service.php?action=check_logged").subscribe((data) => {
      let logged = this.loggedIn;
      this.loggedIn = data['logged'];
      if(this.loggedIn) {
        this.user = { "role":data['role'], "admin":data['admin'], "name":data['username'], 'drone_service':data['drone_service'] }
        this.getDeliveries();
        this.main.closeModal();
        // this.getDealers();
        clearInterval(this.loggedCheckInterval);
        this.loggedCheckInterval = setInterval(() => {
          this.checkStillActive();
        }, 20000);
      } else {
        this.main.closeModal();
        if(logged && !this.loggedIn) {
          alert("Сесията ви изтече, моля влезте отново!");
        }
      }
    });
  }

  checkStillActive() {
    this.http.get("api/service.php?action=check_logged").subscribe((data) => {
      let logged = this.loggedIn;
      this.loggedIn = data['logged'];
      if(this.loggedIn) {
        this.user = { "role":data['role'], "admin":data['admin'], "name":data['username'], "drone_service":data['drone_service'] }
      } else {
        if(logged && !this.loggedIn) {
          alert("Сесията ви изтече, моля влезте отново!");
        }
      }
    });
  }

  Login(email, password, mdl) {
    this.main.openModal('logging-in', {});
    this.http.get("api/service.php?action=login&email=" + email + "&password=" + password).subscribe((data) => {
      this.loggedIn = data['logged'];
      if(data['logged']) {
        this.user = { "role":data['role'], "admin":data['admin'], "name":data['username'], "drone_service":data['drone_service'] }
        this.getDeliveries();
        clearInterval(this.loggedCheckInterval);
        this.loggedCheckInterval = setInterval(() => {
          this.checkStillActive();
        }, 5000);
        // this.getDealers();
        mdl.loginForm = { email:"", password:"" }
      } else {
        mdl.errorOn();
      }

      setTimeout(()=>{ this.main.closeModal(); }, 500);
    });
  }

  Logout() {
    this.main.openModal('logging-out', {});
    this.http.get("api/service.php?action=logout").subscribe((data) => {
      this.loggedIn = data['logged'];
      this.user = { role:-1, admin:"0", name:"" };
      clearInterval(this.loggedCheckInterval);
      setTimeout(()=>{ this.main.closeModal(); }, 500);
    });
  }

  getDeliveries() {
    this.http.get("api/service.php?action=get_deliveries").subscribe((data) => {
      this.deliveries = data['deliveries'];
    });
  }

  getDealers() {
    this.http.get("api/service.php?action=get_dealers").subscribe((data) => {
      this.dealers = data['dealers'];
    });
  }


  setupMain(main) { this.main = main; }

  returnPadZero(number, length) {
    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }
    return my_string;
  }


  returnLogged() { return this.loggedIn; }
  returnUser() { return this.user; }
  returnName() { return this.user.name; }
  returnRole() { return parseInt(this.user.role); }
  returnIsAdmin() {
    if(this.user.admin == "1"){ return true; }
    else { return false; }
  }
  returnTitle() {
    let str = "";
    if(this.user.role == '1') { str = "Склад"; }
    else if(this.user.role == '2') { str = "Дилър"; }
    else if(this.user.role == '3') { str = "Официален дилър"; }
    else if(this.user.role == '4') { str = "Сервиз - Дронове"; }
    else if(this.user.role == '5') { str = "Продавач - Дронове"; }
    return str;
  }

  returnDeliveries() { return this.deliveries; }
  returnDealers() { return this.dealers; }
  returnPublicDocumentName() {
    return this.public_document_name;
  }
}
