import { HttpClient } from '@angular/common/http';
import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LocalSystemService } from '../local-system.service';

import * as XLSX from 'xlsx';

@Component({
  selector: 'app-deliveries',
  templateUrl: './deliveries.component.html',
  styleUrls: ['./deliveries.component.scss']
})
export class DeliveriesComponent implements OnInit {
  deliveries:any = [];
  currentPage:any = 1;
  total_pages = 0;
  total_pages_array = [];

  availableBrands:any = [];
  timeline:any = "0";
  brand:any = "all";
  serial:any = '';
  search_by = 'timebrand';
  searched:any = false;

  modal:any = { open:false, cntx:"", obj:{} }

  constructor(private http:HttpClient, public system:LocalSystemService, private router:Router, private ngZone:NgZone) {
    if(this.system.returnRole() > 1 && this.system.returnRole() < 3 && !this.system.returnIsAdmin()) { 
      // if(this.system.returnName() == 'Дилър' || this.system.returnName() == 'Перфект електра ООД') {
      //   this.router.navigate(['/deliveries-dealers']); 
      // } else {
      //   this.router.navigate(['/warranties']); 
      // }
      this.router.navigate(['/deliveries-dealers']); 
    } else if(this.system.returnRole() == 4  && !this.system.returnIsAdmin() || this.system.returnRole() == 5 && !this.system.returnIsAdmin()) {
      this.router.navigate(['/drone-service']); 
    }
    this.getAvailableBrands();
    this.getDeliveries();
  }

  ngOnInit(): void {
  }


  openFileManagement(event: any){
    event.preventDefault();

    var fileManager = document.getElementById("document-upload");
    fileManager.click();
  }

  onFileChange(event: any) {
    this.openModal('loading-data', {});
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    let container = '';
    if(target.files[0].name.split(" ").length > 1) {
      container = target.files[0].name.split(" ")[0];
    }
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data); // Data will be logged in array format containing objects
      // console.log(XLSX.utils.decode_row('A1')); 
      // for(var i = 0; i < data.length; i++) {
      //   if(data[i]['Сер ном външно'] === undefined && data[i]['"Сер ном вътрешно '] === undefined) { data.splice(i, 1); }
      // }
      let comment = "";
      if(this.system.returnIsAdmin()) { comment = "Разтоварени в Пловдив"; }
      let name = '';
      if(data.length > 1) {
        if(data[0]['Марка'] != 'Избери марка') {
          name = data[0]['Марка'];
        }
      }
      this.openModal('importing-delivery', { 'data_rows':data, 'name':name, document_number:0, container_number:container, date_add:new Date(), 'comment':comment, step:1, current_delivery_date:new Date(), 'data':data })
      this.getAvailableId();
    };
  }


  switchDeliveryExpanded(delivery){
    if(!delivery.expanded) {
      for(let i = 0; i < this.deliveries.length; i++) {
        this.deliveries[i].expanded = false;
      }
    }
    delivery.expanded = !delivery.expanded;
    if(delivery.expanded) {
      this.http.get("api/service.php?action=get_delivery_products&delivery_id=" + delivery.uid).subscribe((data) => {
        delivery.items = data['products'];
      });
    }
  }

  getDeliveries() {
    this.openModal('loading-deliveries', {});
    let url = "api/service.php?action=get_deliveries&page=" + this.currentPage + "&search_by=" + this.search_by;
    if(this.brand != 'all') { url += "&by_brand=" + this.brand; }
    if(this.timeline != '0') { url += "&by_date=" + this.timeline; }
    if(this.serial != '') { url += "&serial=" + this.serial; }
    this.http.get(url).subscribe((data) => {
      if(data['logged']) {

        if(this.search_by == 'serial' && this.serial != '') { this.searched = true; }
        this.deliveries = data['deliveries'];
        this.total_pages = data['total_pages'];
        this.total_pages_array = [];
        for(let i = 0; i < this.total_pages; i++) { this.total_pages_array.push(0); }
        this.ngZone.run(()=> {});
        setTimeout(()=>{
          this.closeModal();
        }, 500);
      } else {
        this.system.Logout();
      }
    });
  }

  switchPage(page) {
    this.currentPage = page;
    this.getDeliveries();
  }

  switchedFilter() { this.getDeliveries(); }

  clearSearched() {
    this.searched = false;
    this.search_by = 'timebrand';
    this.serial = '';
    this.currentPage = 1;
    this.getDeliveries();
  }

  getAvailableBrands() {
    this.http.get("api/service.php?action=get_brands").subscribe((data) => {
      this.availableBrands = data['brands'];
    });
  }

  promptDelete(delivery_id) { this.openModal('remove-delivery', { 'delivery_id':delivery_id }); }
  deleteDelivery(delivery_id) {
    this.http.get("api/service.php?action=remove_delivery&delivery_id=" + delivery_id).subscribe((data) => {
      this.closeModal();
      this.getDeliveries();
    });
  }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj}; } 
  closeModal() { this.modal = { open:false, cntx:"", obj:{} }; }


  openPicker() {
    this.modal.obj.timeoutPicker = setTimeout(()=>{      
      this.modal.obj.delivery_date_picker = true; 
      this.modal.obj.current_delivery_date = new Date(this.modal.obj.date_add);
      this.Formulate(this.modal.obj.current_delivery_date);
    }, 50);
  }

  closePicker(ev) { this.modal.obj.delivery_date_picker = false; }

  selectDate(dt){
    this.modal.obj.date_add.setYear(this.modal.obj.current_delivery_date.getFullYear());
    this.modal.obj.date_add.setDate(dt.date);
    this.modal.obj.date_add.setMonth(dt.month);
    this.modal.obj.delivery_date_picker = false;
  }

  nextMonth() { this.modal.obj.current_delivery_date.setMonth(this.modal.obj.current_delivery_date.getMonth() + 1); this.Formulate(this.modal.obj.current_delivery_date); }
  prevMonth() { this.modal.obj.current_delivery_date.setMonth(this.modal.obj.current_delivery_date.getMonth() - 1); this.Formulate(this.modal.obj.current_delivery_date); }

  Formulate(dt) {
    this.modal.obj.current_delivery_month_dates = [];

    var firstDay = new Date(dt.getFullYear(), dt.getMonth(), 1);
    var lastDay = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
    let predate_num = 0;
    // GENERATE PREDATE

    for(let i = 0; i < 42 - lastDay.getDate(); i++) {
        let day_idx = i;
        let first_day = firstDay.getDay();
        if(first_day == 0) { first_day = 7; }
        if(day_idx < first_day - 1) {
            let negative_day = day_idx * -1;
            let date_reformed = new Date(dt.getFullYear(), dt.getMonth(), negative_day);
            this.modal.obj.current_delivery_month_dates.unshift({ "date": date_reformed.getDate(), "month":date_reformed.getMonth(), "active":false})
            predate_num += 1;
        }
    }
    for(let i = 0; i < lastDay.getDate(); i++) {
        let day_id = i + 1;
        this.modal.obj.current_delivery_month_dates.push({ "date":day_id, "month":dt.getMonth(), "active":true });
    }
    for(let i = 0; i < 42 - lastDay.getDate() - predate_num; i++) {
        let date_reformed = new Date(dt.getFullYear(), dt.getMonth() + 2, i + 1);
        this.modal.obj.current_delivery_month_dates.push({ "date":date_reformed.getDate(), "month":date_reformed.getMonth(), "active":false });
    }
  }

  addImportedDelivery() {
    if(this.modal.obj.name.length > 0) {
      let postObject = { products:[], date: this.returnDateFormatedForStorage(this.modal.obj.date_add) };
      for(let i = 0; i < this.modal.obj.data.length; i++) {
        if(this.modal.obj.data[i]['Марка'] !== undefined) {
          let product = this.modal.obj.data[i];
          postObject.products.push({
           'num':i + 1, splitter:false, 'brand':product['Марка'], model_internal:product['Модел Вътрешно'], model_external: product['Модел Външно'], serial_internal:product['Сер ном вътрешно'], serial_external:product['Сер ном външно']
          });
        }
      }
      this.http.post("api/service.php?action=add_delivery&name=" + this.modal.obj.name + "&comment=" + this.modal.obj.comment + "&delivery_id=" + this.modal.obj.container_number + "&document_number=" + this.modal.obj.document_number + "&document_number=" + this.modal.obj.document_number, postObject).subscribe((data) => {
        this.closeModal();
        this.getDeliveries();
      });
    }
  }

  getAvailableId() {
    this.http.get("api/service.php?action=get_delivery_id").subscribe((data) => {
      this.modal.obj.delivery_uid = parseInt(data['available_id']);
    });
  }

  // <td width="15%">{{ row['Марка'] }}</td>
  // <td width="20%">{{ row['Сер ном вътрешно'] }}</td>
  // <td width="20%">{{ row['Сер ном външно'] }}</td>
  // <td width="20%">{{ row['Модел Вътрешно'] }}</td>
  // <td width="20%">{{ row['Модел Външно'] }}</td>

  returnYear(dt) { return dt.getFullYear(); }
  returnMonth(dt) { return dt.getMonth() + 1; }
  returnMonthName(month) {
    if(month == 1) { return "Януари"; }
    else if(month == 2) { return "Февруари"; }
    else if(month == 3) { return "Март"; }
    else if(month == 4) { return "Април"; }
    else if(month == 5) { return "Май"; }
    else if(month == 6) { return "Юни"; }
    else if(month == 7) { return "Юли"; }
    else if(month == 8) { return "Август"; }
    else if(month == 9) { return "Септември"; }
    else if(month == 10) { return "Октомври"; }
    else if(month == 11) { return "Ноември"; }
    else if(month == 12) { return "Декември"; }
    else { return ""; }
  }
  returnDate(dt) { return dt.getDate();  }

  returnDateAgo() {
   let dt = new Date();
   dt.setMonth(dt.getMonth() - parseInt(this.timeline));
   dt.setDate(1);
   return dt;
  }

  returnDateFormated(dt){
    return this.returnPadZero(dt.getDate(), 2) + "-" + this.returnPadZero(dt.getMonth()+ 1, 2) + "-" + dt.getFullYear();
  }

  returnDateFormatedForStorage(dt) {
    return dt.getFullYear() + "-" + this.returnPadZero(dt.getMonth()+ 1, 2) + "-" + this.returnPadZero(dt.getDate(), 2) +  " " +  this.returnPadZero(dt.getHours(), 2) + ":" + this.returnPadZero(dt.getMinutes(), 2) + ":" + this.returnPadZero(dt.getSeconds(), 2);
  }

  returnPadZero(number, length) {
    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }
    return my_string;
  }



}
